<template>
  <div class="target-container">
    <SpeakingPage
      :wordsInfoList="wordsInfoList"
      :pageInfo="pageInfo"
      @changeWordStatus="changeWordStatus"
    />
  </div>
</template>

<script>
import SpeakingPage from "@/components/Course/CoursePage/SpeakingPage";

export default {
  data() {
    return {
      positionStr: "begin",
      pageInfo: {
        titlePinyin: "Wǒ ài bàba māma.",
        titleHanzi: "我爱爸爸妈妈。",
        bgImg:require('@/assets/img/13-Video/chapter-08/lesson-57-video-1.svg'),
      },
      wordsInfoList: [
        {
          singleWordList: [
            {
              id: 0,
              pinyin: "Nǐ",
              hanzi: "你",
              status: 1,
            },
            {
              id: 1,
              pinyin: "hǎo",
              hanzi: "好",
              status: 1,
            },
          ],
          timeStamp: 5,
        },
        {
          singleWordList: [
            {
              id: 0,
              pinyin: "Wǒ",
              hanzi: "我",
              status: 1,
            },
            {
              id: 1,
              pinyin: "shì",
              hanzi: "是",
              status: 1,
            },
            {
              id: 2,
              pinyin: "Xiǎo",
              hanzi: "小",
              status: 1,
            },
            {
              id: 3,
              pinyin: "Ài.",
              hanzi: "爱",
              status: 1,
            },
          ],

          timeStamp: 10,
        },
        {
          singleWordList: [
            {
              id: 0,
              pinyin: "Nǐ",
              hanzi: "你",
              status: 1,
            },
            {
              id: 1,
              pinyin: "hǎo",
              hanzi: "好",
              status: 1,
            },
            {
              id: 2,
              pinyin: "Wǒ",
              hanzi: "我",
              status: 1,
            },
            {
              id: 3,
              pinyin: "shì",
              hanzi: "是",
              status: 1,
            },
            {
              id: 4,
              pinyin: "Dōng",
              hanzi: "冬",
              status: 1,
            },
            {
              id: 5,
              pinyin: "dong.",
              hanzi: "冬",
              status: 1,
            },
          ],
          timeStamp: 15,
        },
        {
          singleWordList: [
            {
              id: 0,
              pinyin: "Nǐ",
              hanzi: "你",
              status: 1,
            },
            {
              id: 1,
              pinyin: "hǎo",
              hanzi: "好",
              status: 1,
            },
            {
              id: 2,
              pinyin: "Wǒ",
              hanzi: "我",
              status: 1,
            },
            {
              id: 3,
              pinyin: "shì",
              hanzi: "是",
              status: 1,
            },
            {
              id: 4,
              pinyin: "B",
              hanzi: "B",
              status: 1,
            },
            {
              id: 5,
              pinyin: "B",
              hanzi: "B",
              status: 1,
            },
          ],
          timeStamp: 20,
        },
      ],
    };
  },
  methods: {
    changeWordStatus(step, index, status) {
      this.wordsInfoList[step].singleWordList[index].status = status;
    },
  },
  components: {
    SpeakingPage,
  },
};
</script>

<style lang="scss" scoped>
.target-container {
  width: 100%;
  height: 100%;
}
</style>