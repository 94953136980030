<template>
  <div class="game-container">
    <ListenGame
      :trumpetImg="trumpetImg"
      :allImgList="allImgList"
      :answerList="answerList"
      :chooseHanziList="chooseHanziList"
    />
  </div>
</template>

<script>
import ListenGame from "@/components/Course/GamePage/ListenGame";
export default {
  data() {
    return {
      answerList: [2, 7, 16 ,9],
      
      trumpetImg: require("@/assets/img/16-GAMES/G50-stories/trumpter.svg"),
      chooseHanziList: [
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-58/image-2.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/3-yue-8-hao.mp3"),//3.8
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-58/image-7.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/6-yue-24-hao.mp3"),//6.24
    
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-58/image-16.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/11-yue-29-hao.mp3"),//11.29
        },
        {
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-58/image-9.svg`),
          audioSrc: require("@/assets/audio/L1/6-Sentences/10-yue-15-hao.mp3"),//10.15
        },
      
      ],
    };
  },
  computed: {
    allImgList() {
      let imgArr = [];
      for (let i = 1; i <= 16; i++) {
        imgArr.push({
          bgImg: require(`@/assets/img/16-GAMES/G50-stories/lesson-58/image-${i}.svg`),
          id: i,
        });
      }
      return [
        imgArr.slice(0, 4),
        imgArr.slice(4, 8),
        imgArr.slice(12, 16),
        imgArr.slice(8, 12),
      ];
    },
  },
  components: {
    ListenGame,
  },
};
</script>

<style lang="scss" scoped>
.game-container {
  width: 100%;
  height: 100%;
}
</style>