<template>
  <div class="game-container layout-border">
    <FindStroke
      :bgImg="bgImg"
      :gameIndex="gameIndex"
      :strokeImgList="strokeImgList"
      :strockNotFoundImage="strockNotFoundImage"
      :strockFoundImage="strockFoundImage"
    />
  </div>
</template>

<script>
import FindStroke from "@/components/Course/GamePage/FindStrokeGame";
export default {
  data() {
    return {
      bgImg: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/background-snow.svg"),
      gameIndex:58,
      strokeImgList: [
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-club.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-club-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-head.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-head-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-leg.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-leg-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-scarf.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-scarf-found.svg"),
        },
        {
          notFind: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-skier.svg"),
          find: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-skier-found.svg"),
        },
      ],
      strockNotFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-shuwan-pending.svg"),
      strockFoundImage: require("@/assets/img/16-GAMES/G15-find-strokes/lesson-58/stroke-shuwan-found.svg"),
    };
  },
  components: {
    FindStroke,
  },
};
</script>

<style lang="scss" scoped>
</style>
